import React from 'react';
import Layout from '../components/Layout';
import {useTranslation} from 'react-i18next';
import {Link} from 'gatsby';
import Seo from '../components/Seo';
import {withLocaleProvider} from '../locale/LocaleProvider';


export const StoryPage = () => {
  const {t, i18n} = useTranslation();
  return (
    <>
      <Seo
        lang={i18n.language}
        title={t('Story:seo:title')}
        description={t('Story:seo:description')}
        url={t('Story:seo:url')}
      />
      <Layout beforeFooter>
        <div className="breadcrumbs-v3 img-v4">
          <div className="container text-center">
            <p>{t('Story:intro:subtitle')}</p>
            <h1>{t('Story:intro:title')}</h1>
          </div>
        </div>
        <div className="container content-sm">
          <div className="title-v1 no-margin-bottom">
            <p
              className="no-margin-bottom"
              dangerouslySetInnerHTML={{
                __html: t('Story:intro:text'),
              }}
            />
          </div>
        </div>
        <div className="service-block-v4">
          <div className="container content-sm">
            <div className="row">
              <div className="col-md-3 col-sm-6 service-desc md-margin-bottom-50">
                <i className="icon-magic-wand"></i>
                <h3>{t('Story:contents:cards:card-1:title')}</h3>
                <p className="no-margin-bottom">
                  {t('Story:contents:cards:card-1:text')}
                </p>
              </div>
              <div className="col-md-3 col-sm-6 service-desc md-margin-bottom-50">
                <i className="icon-badge"></i>
                <h3>{t('Story:contents:cards:card-2:title')}</h3>
                <p className="no-margin-bottom">
                  {t('Story:contents:cards:card-2:text')}
                </p>
              </div>
              <div className="col-md-3 col-sm-6 service-desc">
                <i className="icon-reload"></i>
                <h3>{t('Story:contents:cards:card-3:title')}</h3>
                <p className="no-margin-bottom">
                  {t('Story:contents:cards:card-3:text')}
                </p>
              </div>
              <div className="col-md-3 col-sm-6 service-desc">
                <i className="icon-reload"></i>
                <h3>{t('Story:contents:cards:card-4:title')}</h3>
                <p className="no-margin-bottom">
                  {t('Story:contents:cards:card-4:text')}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container content-sm">
          <p>{t('Story:contents:text-1:text')}</p>
          <ul>
            <li>{t('Story:contents:text-1:item-1')}</li>
            <li>{t('Story:contents:text-1:item-2')}</li>
            <li>{t('Story:contents:text-1:item-3')}</li>
            <li>{t('Story:contents:text-1:item-4')}</li>
          </ul>
          <br />
          <div className="headline-left margin-bottom-30">
            <h2 className="headline-brd">{t('Story:contents:text-2:title')}</h2>
          </div>
          <p>{t('Story:contents:text-2:text')}</p>
          <ul>
            <li>{t('Story:contents:text-2:item-1')}</li>
            <li>{t('Story:contents:text-2:item-2')}</li>
            <li>{t('Story:contents:text-2:item-3')}</li>
          </ul>
          <br />
          <div className="headline-left margin-bottom-30">
            <h2 className="headline-brd">{t('Story:contents:text-3:title')}</h2>
          </div>
          <p
            dangerouslySetInnerHTML={{
              __html: t('Story:contents:text-3:item-1'),
            }}
          />
          <p
            dangerouslySetInnerHTML={{
              __html: t('Story:contents:text-3:item-2'),
            }}
          />
          <br />
          <div className="headline-left margin-bottom-30">
            <h2 className="headline-brd">{t('Story:contents:text-4:title')}</h2>
          </div>
          <p>{t('Story:contents:text-4:text')}</p>
          <ul>
            <li>{t('Story:contents:text-4:item-1')}</li>
            <li>{t('Story:contents:text-4:item-2')}</li>
            <li>{t('Story:contents:text-4:item-3')}</li>
            <li>{t('Story:contents:text-4:item-4')}</li>
          </ul>
        </div>
      </Layout>
    </>
  );
};

export default withLocaleProvider('it', StoryPage);
